import React from "react"
import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Modal from "@material-ui/core/Modal"
import Backdrop from "@material-ui/core/Backdrop"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "80vw",
    height: "80vh",
    maxWidth: 1000,
    maxHeight: 800,

    border: "none",
    display: "flex",
    outline: "none",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
}))

export default function TransitionsModal({ children, open, setOpen }) {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Box className={classes.content}>{children}</Box>
      </Fade>
    </Modal>
  )
}
