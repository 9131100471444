import React, { useState } from "react"
import cx from "classnames"
import NoSsr from "@material-ui/core/NoSsr"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Modal from "../components/modal"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(12.5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(8),
    },
  },
  image: {},
  imageContainer: {
    cursor: "pointer",
  },
  images: {},
  modalContent: {
    position: "relative",
  },
  controls: {
    top: 0,
    color: theme.palette.common.white,
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: "auto",
    bottom: 0,
    position: "absolute",
  },
  prevBtn: {
    left: `-${theme.spacing(8)}px`,
    [theme.breakpoints.down("sm")]: {
      left: 0,
    },
  },
  nextBtn: {
    right: `-${theme.spacing(8)}px`,
    [theme.breakpoints.down("sm")]: {
      right: 0,
    },
  },
  closeBtn: {
    color: theme.palette.common.white,
    position: "absolute",
    top: `-${theme.spacing(7)}px`,
    right: `-${theme.spacing(7)}px`,
    [theme.breakpoints.down("sm")]: {
      top: 0,
      right: 0,
    },
  },
  closeIcon: {
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
}))

const GalleryPage = ({ data }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(undefined)

  const { datoCmsGalleryPage } = data || {}
  const { seo, gallery = [] } = datoCmsGalleryPage || {}
  const images = gallery.map(image => getImage(image))

  const handleClick = index => {
    setOpen(true)
    setIndex(index)
  }

  const goPrev = index => {
    if (index > 0) {
      setIndex(state => state - 1)
      return
    }
    setIndex(images.length - 1)
  }
  const goNext = index => {
    if (index < images.length - 1) {
      setIndex(state => state + 1)
      return
    }
    setIndex(0)
  }

  return (
    <Layout bgColor="#B136FF">
      <Seo title={seo.title} description={seo.description} />
      <Box className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid key={index} item md={4} sm={6} xs={12}>
                <Box
                  component="span"
                  onClick={() => handleClick(index)}
                  className={classes.imageContainer}
                >
                  <GatsbyImage
                    alt={`Galeria ${index + 1}`}
                    image={getImage(image)}
                    className={classes.images}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
          <NoSsr>
            <Modal index={index} images={images} open={open} setOpen={setOpen}>
              {images[index] && (
                <Box className={classes.modalContent}>
                  <GatsbyImage
                    alt={`Galeria`}
                    image={getImage(images[index])}
                    className={classes.image}
                  />
                  <IconButton
                    size="medium"
                    onClick={() => goPrev(index)}
                    className={cx(classes.controls, classes.prevBtn)}
                    aria-label="prev"
                  >
                    <ChevronLeftIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    size="medium"
                    onClick={() => goNext(index)}
                    className={cx(classes.controls, classes.nextBtn)}
                    aria-label="next"
                  >
                    <ChevronRightIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    size="medium"
                    onClick={() => setOpen(false)}
                    className={classes.closeBtn}
                    aria-label="close"
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </Box>
              )}
            </Modal>
          </NoSsr>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query GelleryPageQuery {
    datoCmsGalleryPage {
      seo {
        title
        description
      }
      gallery {
        gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      }
    }
  }
`

export default GalleryPage
